.team-card-image {
  height: 6rem;
  width: 6rem;
  border-radius: 50px;
  margin-bottom: -40px;
}

.team-card {
  width: 25rem;
  height: 20rem;
  border: 10px solid white;
  text-align: center;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 2px;
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.team-card-active {
  /* width: 25rem;
  height: 20rem; */

  width: 25rem;
  height: 20rem;
  border: 10px solid white;
  text-align: center;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 15px;
  border-radius: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}

.team-heading {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1rem;
  text-align: center;
  color: #000;
  margin-top: 1.5rem;
}

.team-desig {
  font-family: Montserrat;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  color: #2e2f2b;
  margin-top: 0.5rem;
}

.team-jd {
  font-family: Montserrat;
  font-weight: 200;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  color: #2e2f2b;
  margin-top: 0.5rem;
  padding-left: 5px;
  padding-right: 5px;
}

.team-qoute-img {
  height: 2rem;
  width: 2rem;
  margin: 0 auto;
}
