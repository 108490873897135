.modal-img-ai{
    max-width: 100%;
    height: auto;

}

.modal-mod{
    max-height: 15rem;
}

@media (min-width: 400px){
.modal-lg ,.modal-xl{
    max-width: 1300px;
}
}
