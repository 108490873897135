.contactCard-card {
  /* width: 26.0625rem; */

  
  height: 2rem;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.11);
}

.contactcard-icon {
  max-width: 100%;
  max-height: 100%;
}

.contactcard-iconHolder {
  width: 4rem;
  margin: auto 0;
  margin-left: -3rem;
}

.contactCard-TextHolder {
  padding: 1rem;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.11);
  height: auto;
  
  
}

.contactCard-description {
  font-size: 1rem;
  text-align: left;
}

.contactCard-text {
  font-size: 1rem;
  text-align: right;
  color: #00b8bf;
}

.smallcard {
  /* background: url('../../../images/portfolio-bg.svg'); */
}

.contactCard-parentContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media only screen and (max-width: 1200px) {
  .smallcard {
    flex: 0 0 auto;
    width: 100%;
    /* background: url('../../../images/portfolio-bg.svg'); */
  }
  
}