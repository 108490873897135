.footerbackground {
  height: fit-content;
  background-image: url("../../../images/footer-background.svg");
  /* background: white; */
  background-repeat: no-repeat;
}

.footerContentContainer {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media only screen and (max-width: 760px) {
  .ServiceAndLinks {
    display: none;
  }
}

.footer-toTheTop {
  margin-left: auto;
  margin-right: 0;
}
.footer-toTheTop-text {
  text-align: end;
}
