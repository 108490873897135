.FSL-heading {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1.1625rem;
    letter-spacing: 0.11em;
    line-height: 1.5625rem;
    text-align: left;
    color: #221f1f;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
}

/* ================= sub items style =============== */

.sublinks {
    font-family: Montserrat;
    font-weight: 500;
    font-size: .75rem;
    letter-spacing: 0.08em;
    line-height: 1.1875rem;
    text-align: left;
    color: #221f1f;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: underline;
}

ul {
    list-style-position: inside;
    padding-left: 0;
}

li {
    list-style-type: none;
}