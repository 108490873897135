.work-heading {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 100%;
  line-height: 1rem;
  text-align: center;
  color: #000;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.work-item-number {
  margin-top: 1rem;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
}

.work-img {
  margin-top: 1em;
  height: 5rem;
  display: flex;
  justify-content: center;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.work-custom-width {
  width: 20%;
}

.work-arrow-img {
  height: 2rem;
  width: 2rem;
  margin: 0 auto;
  margin-bottom: -3rem;
}

.work-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 19px 37px rgba(34, 31, 31, 0.14);
  padding-bottom: 2rem;
  margin-bottom: 2%;
}

@media only screen and (max-width: 1200px) {
  .ourclients {
    height: auto;
    /* background: url("../../../images/ourClientsBackground.svg"); */
    background: white;
  }
}

@media only screen and (max-width: 990px) {
  .work-custom-width {
    width: 40%;
  }

  .work-item {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 19px 37px rgba(34, 31, 31, 0.14);
    padding-bottom: 2rem;
    margin-bottom: 4%;
  }
}
