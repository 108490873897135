.ourclients {
  height: auto;
  background-image: url("../../../images/ourClientsBackground.svg");
  /* background: white; */
  background-repeat: no-repeat;
  padding-top: 80px;
}

.content {
  margin: auto 0;
}

.heading {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 3.125rem;
  line-height: 3rem;
  text-align: center;
  color: black;
  padding-top: 2rem;
}

.clientItem {
  width: 15rem;
  border-radius: 25px;

  /* background: url(...); */
  /* box-shadow: 0px 12px 33px rgba(0, 0, 0, 0.13); */
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 1200px) {
  .ourclients {
    height: auto;
    /* background: url("../../../images/ourClientsBackground.svg"); */
    background: white;
    padding-top: 80px;
  }

  .clientItem {
    width: fit-content;
    align-self: center;
  }
}

@media only screen and (max-width: 550px) {
  .clientItem {
    width: 15rem;
    border-radius: 25px;

    /* background: url(...); */
    /* box-shadow: 0px 12px 33px rgba(0, 0, 0, 0.13); */
    margin-bottom: 2rem;
  }
}
