.industry-heading {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1rem;
  text-align: center;
  color: #000;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.industry-img {
  margin-top: 1em;
  height: 5rem;
  display: flex;
  justify-content: center;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.custom-width {
  width: 18%;
  margin-left: 0.1%;
  margin-right: 0.1%;
}

.industry-item {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 19px 37px rgba(34, 31, 31, 0.14);
  padding: 0px;
  margin-bottom: 2%;
}

.industry-button-container {
  display: flex;
}

@media only screen and (max-width: 1200px) {
  .ourclients {
    height: auto;
    /* background: url("../../../images/ourClientsBackground.svg"); */
    background: white;
  }
}

@media only screen and (max-width: 990px) {
  .custom-width {
    width: 40%;
  }

  .industry-item {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 19px 37px rgba(34, 31, 31, 0.062);
    margin-bottom: 4%;
  }
}
