.industry-section {
  height: 800px;
  background: white;
  padding-top: 80px;
}

@media only screen and (max-width: 1200px) {
  .industry-section {
    height: auto;
    /* background: url("../../../images/ourClientsBackground.svg"); */
    background: white;
    padding-top: 80px;
  }
}
