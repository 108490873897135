.portfolio {
  height: 700px;
  /* background: linear-gradient(#00b8bf, #68e3e8); */
  background: url("../../../images/ourClientsBackground.svg");
  background-repeat: no-repeat;
  padding-top: 80px;
}

/* .content {
  margin: auto 0;
} */

.pheading {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 3.125rem;
  line-height: 3rem;
  text-align: center;
  color: rgb(0, 0, 0);
  padding-top: 2rem;
}
.col-center-block {
  float: none;
  display: block;
  margin: 0 auto;
  /* margin-left: auto; margin-right: auto; */
}
.headingUnderline {
  width: 6rem;
  height: 0px;
  border-radius: 1rem;
  background: #f79633;
  border: 7px solid #f79633;
  margin: 2rem auto 2rem auto;
}

.portfolioItem {
  width: 85%;
  height: 19.313rem;
  border-radius: 2.125rem;
  background: #fff;
  box-shadow: 0px 19px 37px rgba(34, 31, 31, 0.14);
  flex: auto;
}

.portfolioItemContainer {
  justify-content: space-between;
  margin: 0 4rem;
}

.itemOneAndFive {
  margin-top: 8rem;
}

.itemTwoAndFour {
  margin-top: 4rem;
}

.pImgSpacing {
  margin-top: 4rem;
  height: 3rem;
  display: flex;
  justify-content: center;
}

.pItemHeading {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.75rem;
  text-align: center;
  color: #f79633;
  margin-top: 2.5rem;
}

.pItemText {
  margin-top: 1rem;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: rgb(0, 0, 0);
  padding-left: 1rem;
  padding-right: 1rem;
}

@media only screen and (max-width: 1200px) {
  .portfolio {
    background: linear-gradient(#00b8bf, #68e3e8);
    background-repeat: no-repeat;
    /* background: url('../../../images/portfolio-bg.svg'); */
  }
  .portfolioItemContainer {
    justify-content: none;
  }
  .itemOneAndFive {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .itemTwoAndFour {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .itemThree {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
