.tab-component {
  flex: 1;
  height: 90%;
  margin: auto 5px;
  box-shadow: 0px 9px 10px rgba(34, 31, 31, 0.14);
  border-radius: 10px;
  cursor: pointer;
}

.tab-component-disabled {
  flex: 1;
  height: 90%;
  margin: auto 5px;
  cursor: pointer;
}

.tab-diamond-img {   
  width: 12%;
  height: 12%;
  margin: 0 auto;
  display: flex;
}

.tab-diamond-img-lg {
  width: 12%;
  height: 12%;
  margin: 0 auto;
  margin-top: 16%;
  display: flex;
}

.tab-diamond-img-img {
  -webkit-filter: drop-shadow(4px 4px 4px rgba(34, 31, 31, 0.14));
  filter: drop-shadow(4px 4px 4px rgba(34, 31, 31, 0.14));
}

.tab-diamond-img-img-disabled {
  flex: 1;
}

.tab-heading-disabled {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  color: #6d6666;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.enclosing-tab {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 10rem;
  width: 11.5rem;
  margin-bottom: 1rem;
}
.tab-heading {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  color: #000;
  margin-top: 0.5rem;
}

.tab-img {
  margin-top: 20%;
  height: 3rem;
  display: flex;
  justify-content: center;
}
