.service-module {
    max-width: max-content;
    margin-left: 0;
}

.service-ai {
        width: -webkit-fill-available;
}

.service-detail-border {
    border-radius: 28px;
    background: transparent;
    border: 1px solid #dbd9d9;
    margin-left: 0.2rem;
    padding-top: 1rem;
}