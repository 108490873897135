.contactUs {
  height: fit-content;
  /* background: linear-gradient(#00b8bf, #68e3e8); */
  /* background: url("../../../images/portfolio-bg.svg"); */
  padding-top: 80px;
  margin-bottom: 80px;
}

.contactUs-heading {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 3.125rem;
  line-height: 3rem;
  text-align: center;
  color: black;
  padding-top: 2rem;
}

.contactUs-formParentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
}
