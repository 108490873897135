.our-team {
  height: auto;

  /* background: white; */
  background-repeat: no-repeat;
  overflow: hidden;
  margin-top: 4rem;
  margin-bottom: 1rem;
  padding-top: 80px;
}
