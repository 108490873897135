.home-carousal-parent {
  width: 80%;
  height: 38rem;
  align-self: center;
  background-color: #ebfafa;
  margin: 0 auto;
}

.home-section {
  background-color: #ebfafa;
  padding-top: 80px;
}
