.services-section {
  height: fit-content;
  /* background: linear-gradient(#00b8bf, #68e3e8); */
  /* background: url("../../../images/portfolio-bg.svg"); */
  padding-top: 80px;
}

.services-tabLayout {
  justify-content: space-around;
}

.services-section-heading {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 3.125rem;
  line-height: 3rem;
  text-align: center;
  color: black;
  padding-top: 2rem;
}

.react-horizontal-scrolling-menu--scroll-contain {
  display: flex;
  height: max-content;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  justify-content: space-around;
}
