.navButtonStyle {
  margin-left: 10px;
  background-color: #f79633;
  outline-color: #f79633;
  border-radius: 0.5rem;
  border-color: #f79633;
  width: 150px;
}

.navBarStyle {
  height: 80px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  z-index: 999;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1450px;
  }
}

@media only screen and (max-width: 992px) {
  .navBarStyle {
    height: auto;
  }
}

body {
  font-family: "Montserrat", sans-serif;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
