.home-component {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
}

.home-component .top-section {
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.top-section img {
  flex: 1;
  overflow: hidden;
}

.home-component .bottom-section {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}
