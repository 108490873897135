.home-service-comp {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.home-service-img-div {
  width: 4rem;
  height: 4rem;
  border-radius: 2rem;
  justify-content: center;
  padding: 0.5rem;
  align-self: center;
}

.home-service-img-div img {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.home-service-comp h1 {
  font-family: Montserrat;
  font-weight: 350;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  color: #000;
  margin-top: 0.5rem;
}
