.contactForm {
  box-shadow: 20%;
  border-radius: 0.5rem;
  border: 0px;
  max-height: 100%;
  min-height: 100%;
  flex: 1;
  resize: none;
}

.div-flex {
  display: flex;
}

.contactFormContainer {
  width: 100%;
  height: fit-content;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 12px 44px rgba(0, 0, 0, 0.11);
}

.contactForm-height-50 {
  height: 3rem;
}

.contactForm-height-150 {
  height: 12rem;
}
