.contactButtonStyle{
    /* margin-left: 10px; */
    background-color: #F79633;
    outline-color: #F79633;
    border-radius: 2rem;
    border-color: #F79633;
    width: 12.5rem;
    align-items: center;
    margin: 0 auto;
    padding: 1rem .7rem;
    margin-top: 1rem;
}