.work-section {
  height: 800px;
  background-image: url("../../../images/ourClientsBackground.svg");
  /* background: white; */
  background-repeat: no-repeat;
  padding-top: 80px;
}

@media only screen and (max-width: 1200px) {
  .work-section {
    height: auto;
    /* background: url("../../../images/ourClientsBackground.svg"); */
    background: white;
    padding-top: 80px;
  }
}
